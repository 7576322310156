"use client";
import Button from "../../Button";

export default function MenuMobile({ items }) {
  const toggleMenu = () => {
    const menuClosedIcon = document.querySelector("#menu-closed-icon");
    const menuOpenIcon = document.querySelector("#menu-open-icon");
    const menu = document.querySelector("nav#menu");

    menuClosedIcon?.classList.toggle("hidden");
    menuOpenIcon?.classList.toggle("hidden");
    menu?.classList.toggle("opacity-100");
    menu?.classList.toggle("hid");

    if (!menu?.className?.includes("hid")) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  return (
    <>
      <button
        onClick={() => toggleMenu()}
        className="border-none bg-none outline-none sm:block hidden"
      >
        <svg
          id="menu-closed-icon"
          className="transition duration-300"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 111 72"
          fill="none"
        >
          <path
            d="M0.451172 10.0737V0.621094H97.2142V10.0737H0.451172Z"
            fill="white"
          />
          <path
            d="M110.624 0.621094V10.0737H101.889V0.621094H110.624Z"
            fill="white"
          />
          <path
            d="M0.451172 61.6041V71.0566H97.2142V61.6041H0.451172Z"
            fill="white"
          />
          <path
            d="M110.624 71.0566V61.6041H101.889V71.0566H110.624Z"
            fill="white"
          />
          <path
            d="M110.625 40.6772V31.2246H14.1999V40.6772H110.625Z"
            fill="white"
          />
          <path
            d="M9.52472 31.2246H0.451202V35.9509V40.6772H9.52472V31.2246Z"
            fill="white"
          />
        </svg>
        <svg
          id="menu-open-icon"
          className="hidden transition duration-300"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 73 73"
          fill="none"
        >
          <path
            d="M64.5188 0L72.0832 7.57618L64.9625 14.6974L57.43 7.09308L64.5188 0Z"
            fill="white"
          />
          <path
            d="M0.0283813 64.384L52.6413 11.8825L60.0685 19.7313L7.70489 72.0543L0.0283813 64.384Z"
            fill="white"
          />
          <path
            d="M6.67961 0.914741L0.0284348 7.57623L0.0468903 7.59468L1.52588e-05 7.64189L64.4067 72.0543L72.0833 64.384L9.71078 2.11923L7.59288 5.31997e-05L7.59042 0.00252496L7.58788 0L6.67961 0.914741Z"
            fill="white"
          />
        </svg>
      </button>

      <nav
        id="menu"
        className="opacity-0 hid absolute transition duration-300 top-[80px] z-10 right-0 overflow-y-visible w-full max-w-md bg-white sm:max-w-none py-6 shadow-lg"
      >
        {items.map((item) => (
          <div
            onClick={() => toggleMenu()}
            className={`px-3 py-2 font-semibold text-gray-900`}
            key={item.text}
          >
            <a href={item.link} className="flex gap-2 items-center">
              {item.text}
            </a>
          </div>
        ))}

        <div className="w-full h-px bg-gray-200 my-6" />

        <div className="flex flex-col px-4 gap-2">
          <a className="w-full" href={'https://api.whatsapp.com/send?phone=5522998588056'}>
            <Button className="w-full">Saiba mais</Button>
          </a>
        </div>
      </nav>
    </>
  );
}
