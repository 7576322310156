import heroimg from "../../assets/heroimg.png";
import Button from "../../components/Button";

export default function Hero() {
  return (
    <div className="w-full py-16 sm:py-12 flex gap-8 justify-between items-center sm:flex-col">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col max-w-[600px] sm:max-w-none sm:w-full gap-2 text-gray-300 text-lg sm:text-base">
          <h1 className="font-semibold text-white text-6xl font-title tablet:text-4xl">
            Transforme sua Empresa
          </h1>
          <p>
            Nós somos uma empresa especializada em consultoria para criação e
            gestão de produtos digitais, criação de landing pages e
            desenvolvimento de software completos
          </p>
          <p>Entre em contato conosco para mais detalhes:</p>
        </div>

        <a className="sm:w-full" href={'https://api.whatsapp.com/send?phone=5522998588056'}>
          <Button className="sm:w-full" size="xl">
            Entrar em contato
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 41 69"
              fill="none"
            >
              <path d="M27.4421 34.3561L0.146118 7.15099L6.85592 0.480468L40.9886 34.4101L16.1772 59.0826L9.37744 52.3607L27.4421 34.3561Z" />
              <path d="M6.93057 68.2931L0.146121 61.5612L4.76178 56.961L11.5576 63.6916L6.93057 68.2931Z" />
            </svg>
          </Button>
        </a>
      </div>

      <img
        src={heroimg}
        className="w-[450px] sm:max-w-none sm:w-full"
        alt="três mulheres olhando para papéis importantes da empresa"
      />
    </div>
  );
}
