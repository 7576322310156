import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Hero from './container/Hero';

function App() {
  return (
    <div className="App">
      <Header />
      <main className="max-w-[1460px] mx-auto px-20 md:px-5 w-full xl:px-4">
        <Hero />
      </main>
    </div>
  );
}

export default App;
