import clsx from "clsx";
import { ButtonHTMLAttributes, ReactNode } from "react";

// export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
//   variant?: 'primary' | 'secondary' | 'tertiary' | 'text' | 'error'
//   children: ReactNode
//   isLoading?: boolean
//   isLoadingShort?: boolean
//   size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
// }

export default function Button({
  isLoading = false,
  isLoadingShort = false,
  variant = "primary",
  children,
  size = "sm",
  ...props
}) {
  return (
    <button
      {...props}
      className={clsx(
        "font-semibold min-h-10 w-auto text-base outline-none flex flex-row gap-2 items-center justify-center rounded-lg transition duration-300",
        props?.className,
        {
          "bg-primary-800 border text-secondary-400 [&>*]:fill-secondary-400 border-secondary-400 disabled:opacity-80 hover:bg-primary-700 hover:border-primary-700 hover:text-white [&>*]:hover:fill-white focus:outline focus:outline-4 focus:outline-gray-100":
            variant === "primary",
          "bg-secondary-400 text-primary-700 font-bold border border-secondary-400 hover:bg-secondary-100 focus:outline focus:outline-4 focus:outline-gray-100 disabled:bg-secondary-300 disabled:border-secondary-300 disabled:text-primary-300":
            variant === "secondary",
          "bg-white text-gray-700 border border-gray-300 hover:bg-gray-30 focus:outline focus:outline-4 focus:outline-gray-100 disabled:bg-white disabled:border-gray-200 disabled:text-gray-300":
            variant === "tertiary",
          "bg-red-600 text-white border border-red-600 hover:bg-red-700 hover:border-red-700 focus:outline focus:outline-4 focus:outline-red-50 disabled:bg-red-200 disabled:border-red-200":
            variant === "error",
          "bg-none border-none text-gray-600 hover:bg-gray-50 disabled:text-gray-300 outline-none focus:outline-none":
            variant === "text",
          "cursor-not-allowed": props?.disabled,
          "px-3.5 py-2 text-sm": size === "xs",
          "px-4 py-2.5": size === "sm",
          "px-[18px] py-2.5": size === "md",
          "px-5 py-3": size === "lg",
          "px-7 py-4": size === "xl",
        },
      )}
    >
      {isLoading ? (
        <>
          <svg
            aria-hidden="true"
            role="status"
            className="inline w-4 h-4 mr-3 text-white animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
          {!isLoadingShort && "Carregando..."}
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
}
