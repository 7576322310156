import logo from "../../assets/logo.svg";
import Button from "../Button";
import MenuMobile from "./MenuMobile";

const links = [
  // {
  //   text: 'Sobre nós',
  //   link: '#sobre',
  // },
  // {
  //   text: 'Nossos serviços',
  //   link: '#servicos',
  // },
  {
    text: "Contato",
    link: 'https://api.whatsapp.com/send?phone=5522998588056',
  },
];

export default function Header() {
  return (
    <div className="border-b border-b-gray-200 h-20 w-full py-4 bg-primary-800">
      <header className="flex items-center justify-between shrink-0 max-w-[1460px] shadow-xs mx-auto px-20 md:px-5 xl:px-4 h-full w-full">
        <a href="/#">
          <img
            src={logo}
            alt="logo da BML+, um círculo com três ondas dentro saindo pra fora"
            className="h-[50px] sm:h-10 w-auto"
          />
        </a>

        <nav className="flex items-center gap-4 sm:hidden">
          {links.map((item) => (
            <a
              href={item.link}
              key={item.text}
              className="font-semibold text-white"
            >
              {item.text}
            </a>
          ))}

          <a href={'https://api.whatsapp.com/send?phone=5522998588056'}>
            <Button>Saiba mais</Button>
          </a>
        </nav>

        <MenuMobile items={links} />
      </header>
    </div>
  );
}
